import React from 'react'
import './Loom.css'

function Loom() {
  return (
    <div className="Loom">
      <h3>Passive income</h3>
    </div>
  )
}

export default Loom
