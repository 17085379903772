import React from 'react'
import './Art.css';

function Art() {
  return (
    <div className="art">
        <h3>Future Is AI</h3>
    </div>
  )
};

export default Art
